/* Add your profile image here */
.Photo {
  background-image: url("https://i.ibb.co/ZKC3H4X/2-A9-F73-E0-71-A0-4-C13-A309-C8-F241-E3-F41-D.jpg");
}
/* Projects Photo */
.project1 {
  background-image: url("https://i.ibb.co/VjvPRgx/IR-ss.png");
  cursor: none;
}
.project2 {
  background-image: url("https://i.ibb.co/Ss3FyKN/tackle-ss.png");
  cursor: none;
}
.project3 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dumiport.appspot.com/o/Screenshot%20(197).png?alt=media&token=6e8233db-001d-4c17-9067-d444578cc54c");
  cursor: none;
}
.project4 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dumiport.appspot.com/o/develop-your-react-js-web-app.jpg?alt=media&token=a304f8a3-74c1-47d9-aab8-adf2ec4e5a2a");
  cursor: none;
}
.project5 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dumiport.appspot.com/o/Screenshot%20(195).png?alt=media&token=09731b3f-d681-4ee0-a0ff-6c7d37c712a8");
  cursor: none;
}
.project6 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dumiport.appspot.com/o/Screenshot%20(196).png?alt=media&token=cbfc9b68-e6f0-4778-a85d-174c8430cdb4");
  cursor: none;
}
/* Miscelaneous Activities photo */
.misc1 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Github+Repo+Viewer");
  cursor: none;
}
.misc2 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Instagram+Clone");
  cursor: none;
}
.misc3 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Mask+Detector");
  cursor: none;
}
.misc4 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Chrome+Extension");
  cursor: none;
}
.misc5 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Simple+Web+Server+-+C");
  cursor: none;
}
.misc6 {
  background-image: url("https://via.placeholder.com/400x600.png?text=To-Do+App");
  cursor: none;
}
