@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
:root {
  --primary-color: #e96224;
  --hover-color: #ff6600;
}

.dmcv {
  /* center childs */
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}
